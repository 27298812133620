/* eslint-disable react/jsx-key */
import React, { lazy } from 'react'
import AuthorizedRoute from 'base-shell/lib/components/AuthorizedRoute/AuthorizedRoute'
import UnauthorizedRoute from 'base-shell/lib/components/UnauthorizedRoute/UnauthorizedRoute'
import { Route } from 'react-router-dom'

const SignIn = lazy(() => import('../containers/SignIn'))
const SignUp = lazy(() => import('../containers/SignUp'))
const PasswordReset = lazy(() => import('../pages/PasswordReset/PasswordReset'))
const About = lazy(() => import('../pages/About/About'))
const Home = lazy(() => import('../pages/Home/Home'))
const VideoBoard = lazy(() => import('../containers/VideoBoard'))
const VideoUploadBoard = lazy(() => import('../containers/VideoUploadBoard'))
const PocketBoard = lazy(() => import('../containers/PocketBoard'))
const VideoDetailBoard = lazy(() => import('../containers/VideoDetailBoard'))
const SearchBoard = lazy(() => import('../containers/SearchBoard'))
const ReservationDetail = lazy(() => import('../components/ReservationDetail'))

const routes = [
  <UnauthorizedRoute path="/signin" redirectTo="/" exact component={SignIn} />,
  <UnauthorizedRoute path="/signup" redirectTo="/" exact component={SignUp} />,
  <UnauthorizedRoute
    path="/password_reset"
    redirectTo="/"
    exact
    component={PasswordReset}
  />,
  <Route path="/videoBoard" exact component={VideoBoard} />,
  <Route path="/videoUploadBoard" exact component={VideoUploadBoard} />,
  <Route path="/pocketBoard" exact component={PocketBoard} />,
  <Route path="/VideoDetailBoard/:id" exact component={VideoDetailBoard} />,
  <Route path="/ReservationDetail/:id" exact component={ReservationDetail} />,
  <Route path="/about" exact component={About} />,
  <Route path="/searchBoard" exact component={SearchBoard} />,
]

export default routes
