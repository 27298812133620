import red from '@material-ui/core/colors/red'
import pink from '@material-ui/core/colors/pink'
import green from '@material-ui/core/colors/green'
import blue from '@material-ui/core/colors/blue'

const themes = [
  {
    id: 'default',
    color: blue[700],
    source: {
      palette: {
        primary: { main: blue[700], contrastText: '#fff',},
        secondary: red,
        error: red,
      },
    },
  },
]

export default themes
